
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

// Vue.component('example', require('./components/Example.vue'));

// const app = new Vue({
//     el: 'body'
// });

// Not used... see /js/app.js 

/////////////////////////////////////////////
// Document Ready Function
/////////////////////////////////////////////
$(document).ready(function(){
    // header id with profile class only
    // $('#header.profile').each(function(){
    // $('.social i').each(function(){
    //     var $this = $(this),
    //          colors = ['#ec008c', '#00bcc3', '#5fb26a', '#fc7331'];
        
    //     setInterval(function(){
    //         var color = colors.shift();    
    //         colors.push(color);
    //         $this.animate({color: color}, 2000);
    //     },40);
    // });

    $('.social i').each(function(){
        var $this = $(this);
        // colors = ['#ec008c', '#00bcc3', '#5fb26a', '#fc7331', '#336699'];
        colors = ['#ddd', '#ddd', '#ddd', '#ddd', '#ffffff'];
        setInterval(function(){
            var color = colors.shift();    
            colors.push(color);
            $this.animate({color: color}, 2000);
        },4000);
    });

    // colors[(counter++)%colors.length]
});



// animated-canvas-bg
// requires '/FSS.js';

// $(document).ready(function(){
//     var container = document.getElementById('animated-canvas-bg');
//     var renderer = new FSS.CanvasRenderer();
//     var scene = new FSS.Scene();
//     var light = new FSS.Light('323A45', '323A45');
//     var geometry = new FSS.Plane(2000, 1000, 15, 8);
//     var material = new FSS.Material('FFFFFF', 'FFFFFF');
//     var mesh = new FSS.Mesh(geometry, material);
//     var now, start = Date.now();

//     function initialise() {
//       scene.add(mesh);
//       scene.add(light);
      
//       container.style = 'position: absolute; height: 100%; width: 100%;';

//       container.appendChild(renderer.element);
//       window.addEventListener('resize', resize);
//     }

//     function resize() {
//        renderer.setSize(container.offsetWidth, container.offsetHeight);
//     }

//     function animate() {
//       now = Date.now() - start;
//       light.setPosition(300*Math.sin(now*0.001), 200*Math.cos(now*0.0005), 60);
//       renderer.render(scene);
//       requestAnimationFrame(animate);
//     }

//     initialise();
//     resize();
//     animate();
// });